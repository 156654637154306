/* eslint-env browser, jquery */
/* eslint-disable max-len */
(function () {
  'use strict';

  window.nasos.rek = window.nasos.rek || {};
  window.nasos.rek.downloadButton = window.nasos.rek.downloadButton || {};

  window.nasos.rek.downloadButton.config = {
    enabled: false,
    intervalMillis: 0,
    callback: function () { }
  };

})();
