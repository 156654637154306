/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function () {
  'use strict';

  window.nasos.listWidget = {

    init: function () {
      var widget = this;

      if (widget.isYandexBot()) {
        $('#n-query-banned-text').text('');
        $('#n-query-banned').css('display', 'table-row');
        widget.hideLoadingAnimation();

      } else if (window.nasos.env.isPlaylistPage()) {
        nasos.metrics.reachGoal('UI_LIST_LOADING_PLAYLIST');

        var playlistId = window.nasos.env.getPlaylistIdFromUrl();

        $.ajax({
          url: window.nasos.env.backendUrl + '/api/song/playlist/get_songs/' +
            encodeURIComponent(playlistId) +
            '?origin=' + encodeURIComponent('gettune.pro'),
          dataType: 'jsonp',

          success: function (response) {
            widget.updateDescription(response);
            widget.printSongs(response);
          }
        });
      } else {
        var query = window.nasos.env.getQueryFromUrl();

        if (nasos.bann.engine.isQueryBanned(query)) {
          $('#n-query-banned-text').text('К сожалению, данная страница заблокирована правообладателем');
          $('#n-query-banned').css('display', 'table-row');
          widget.hideLoadingAnimation();
          return;
        }

        if (query === '') {
          nasos.metrics.reachGoal('UI_LIST_LOADING_POPULAR');

          $.ajax({
            url: window.nasos.env.backendUrl + '/api/song/popular/get_songs' +
              '?origin=' + encodeURIComponent('gettune.pro'),
            dataType: 'jsonp',

            success: function (response) {
              widget.printSongs(response);
            }
          });
        } else {
          nasos.metrics.reachGoal('UI_LIST_LOADING_SEARCH');

          var searchPageType = window.nasos.env.getSearchPageType();
          // Probably will need in future
          // nasos.metrics.reachGoal('UI_SEARCH_TYPE_' + searchPageType.toUpperCase());

          $.ajax({
            url: window.nasos.env.backendUrl + '/api/song/search/do' +
              '?origin=' + encodeURIComponent('gettune.pro') +
              '&query=' + encodeURIComponent(query) +
              '&type=' + encodeURIComponent(searchPageType),
            dataType: 'jsonp',

            success: function (response) {
              widget.updateDescription(response);
              widget.printSongs(response);
            }
          });
        }
      }
    },

    isYandexBot: function () {
      return navigator && navigator.userAgent && navigator.userAgent.includes('+http://yandex.com/bots');
    },

    updateDescription: function (response) {
      var songs = response.songs;

      if (songs && songs.length > 0) {
        if (songs.length === 1) {
          window.nasos.page.setDescriptionWith1Song(songs[0]);
        } else {
          window.nasos.page.setDescriptionWith2Songs(songs[0], songs[1]);
        }
      }
    },

    hideLoadingAnimation: function () {
      $('#n-load').removeClass('is-active');
      $('#n-load-container').css('display', 'none');
    },

    printSongs: function (response) {
      nasos.metrics.reachGoal('UI_LIST_LOADED');

      var widget = this;

      var songs = response.songs;
      var searchVkUserId = response.vkUserId;

      if (!songs || !songs.length) {
        $('#n-empty-search-text').text('По вашему запросу ничего не найдено');
        $('#n-empty-search').css('display', 'table-row');
      }

      var songsTableEl = $('#n-songs > tbody');
      var rekListTopBannerRowEl = $('#n-rek-list-top-banner-row');
      var rekListMiddleBannerRowEl = $('#n-rek-list-middle-banner-row');

      var beforeBannTimestamp = new Date().getTime();
      $(songs).each(function () {
        this.isSongBanned = window.nasos.bann.engine.isSongBanned(this.artist, this.title);
      });
      var afterBannTimestamp = new Date().getTime();
      console.log('To bann all songs it takes in millis: ', (afterBannTimestamp - beforeBannTimestamp));

      $(songs).each(function (songIndex) {
        widget.printSong(this, songsTableEl, rekListTopBannerRowEl, rekListMiddleBannerRowEl,
          searchVkUserId, songIndex, songs);
      });
      this.hideLoadingAnimation();

      window.componentHandler.upgradeAllRegistered();
    },

    calculateSongFields: function (song, searchVkUserId, songIndex, songs) {
      song.downloadFileName = (song.artist + '-' + song.title)
          .replace(/[\\!\*"'\(\);:@&=\+\$,/\?%#\[\]\n]/gm, '-')
          .substring(0, 2000) +
        '-gettune.pro.mp3';

      var futureDownloadUrls = [];
      for (var i = songIndex + 1; ((i < songs.length) && (i < songIndex + 10)); i++) {
        if ((!!songs[i].url) && (songs[i].url.indexOf('sid://') === 0)) {
          futureDownloadUrls.push(songs[i].url);
        }
      }

      song.downloadUrl = window.nasos.env.backendUrl +
        '/api/song/download/get/11/' +
        encodeURIComponent(song.downloadFileName) +
        '?origin=' + encodeURIComponent('gettune.pro') +
        '&url=' + encodeURIComponent(window.nasos.unMaskVkUrl(song.url, searchVkUserId)) +
        '&artist=' + encodeURIComponent(song.artist) +
        '&title=' + encodeURIComponent(song.title) +
        '&index=' + encodeURIComponent(songIndex) +
        '&user_id=' + encodeURIComponent(searchVkUserId) +
        '&future_urls=' + encodeURIComponent(futureDownloadUrls.join(','));

      song.durationStr = window.nasos.utils.secondsToStr(song.duration);

      song.artistQueryUrl = window.nasos.env.buildSearchByArtistUrl(song.artist);
      song.songQueryUrl = window.nasos.env.buildSearchBySongUrl(song.artist + ' - ' + song.title);
    },

    showDownloadButtonRekIfNeeded: function () {
      const rekEnabled = nasos.rek.downloadButton.config.enabled;
      if (!rekEnabled) { return; }

      const rekWasShown = nasos.utils.localStorageGet('ttn-download-button-rek-shown', false, true);
      if (rekWasShown) { return; }

      const rekShowIntervalMillis = nasos.rek.downloadButton.config.intervalMillis;
      nasos.utils.localStorageSet('ttn-download-button-rek-shown', true, rekShowIntervalMillis);

      try { nasos.rek.downloadButton.config.callback();} catch (ignore) { }
    },

    printSong: function (song, songsTableEl, rekListTopBannerRowEl, rekListMiddleBannerRowEl,
      searchVkUserId, songIndex, songs) {

      var widget = this;

      this.calculateSongFields(song, searchVkUserId, songIndex, songs);

      var rowEl = $('<tr></tr>');
      if (song.isSongBanned) {
        rowEl.addClass('n-songs-banned-row');
      }

      var playButton = window.nasos.player.widget
        .addNextSongAndBuildButton(song, rowEl);

      var downloadButton = $('<a rel="nofollow" ' +
        'class="mdl-button mdl-js-button mdl-button--fab ' +
        'mdl-button--mini-fab mdl-js-ripple-effect mdl-button--accent" ' +
        (song.isSongBanned ? 'disabled' : '') + '/>')
        .attr('download', song.downloadFileName)
        .append($('<i class="material-icons">file_download</i>'))
        .click(function () { widget.showDownloadButtonRekIfNeeded(); });
      if (!song.isSongBanned) {
        downloadButton.attr('href', song.downloadUrl);
      }

      var downloadButtonWrapper = $('<span/>')
        .click(function () {
          nasos.metrics.reachGoal('UI_LIST_DOWNLOAD');
        })
        .append(downloadButton);

      rowEl.append($('<td class="n-songs-button-cell n-songs-padding-right ' +
        'n-songs-padding-left"/>')
        .append(playButton)
        .append(downloadButtonWrapper)
      );

      var songTitleElement = (window.nasos.env.isSearchBySongUrlPrefixEnabled())
        ? $('<a class="mdl-color-text--grey-900"/>')
          .attr('href', song.songQueryUrl)
          .text(song.title)
        : $('<span/>').text(song.title);

      rowEl.append(
        $('<td class="mdl-data-table__cell--non-numeric n-wrap ' +
          'n-songs-padding-right"/>')
          .append($('<p class="n-songs-title"/>')
            .append(songTitleElement)
          )
          .append($('<a class="mdl-color-text--grey-600"/>')
            .attr('href', song.artistQueryUrl)
            .text(song.artist)
          )
          .append($('<p class="mdl-color-text--grey-400 ' +
            'n-songs-banned-text"/>')
            .text('Композиция удалена по просьбе правообладателя'))
      );

      rowEl.append(
        $('<td class="mdl-color-text--grey-500 n-songs-padding-right"/>')
          .text(song.durationStr)
      );

      if (songIndex < 15) {
        rekListTopBannerRowEl.before(rowEl);
      } else if (songIndex < 30) {
        rekListMiddleBannerRowEl.before(rowEl);
      } else {
        songsTableEl.append(rowEl);
      }
    }

  };
})();
