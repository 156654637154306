/* eslint-env browser */
/* eslint-disable max-len */
(function () {
  'use strict';

  nasos.bann = nasos.bann || {};
  nasos.bann.engine = {

    isSongBanned: function (artist, title) {
      var artistRules = nasos.bann.artistRules;
      var titleRules = nasos.bann.titleRules;

      for (var i = 0; i < artistRules.length; i++) {
        if ((artistRules[i].test(artist)) && (titleRules[i].test(title))) {
          return true;
        }
      }
      return false;
    },

    isQueryBanned: function (query) {
      query = query || '';
      query = query.toLowerCase().trim();

      return _.contains(nasos.bann.queryRules, query);
    }

  };

})();
