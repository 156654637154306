/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function () {
  'use strict';

  nasos.search = nasos.search || {};

  nasos.search.widget = {

    init: function () {
      var queryEl = $('#n-query');

      var search = function () {
        location.href = nasos.env.buildSearchByTextUrl(queryEl.val());
      };

      $('#n-search').click(search);

      queryEl.keypress(function (event) {
        if (event.which === 13) {
          search();
        }
      });

      if (!nasos.env.isPlaylistPage()) {
        var query = nasos.env.getQueryFromUrl();

        if (query) {
          queryEl.val(query);
          $('div:has(#n-query)').addClass('is-dirty');
        }
      }
    }

  };
})();
