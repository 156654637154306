/* eslint-env browser, jquery */
/* global MediaElement */
/* eslint-disable require-jsdoc */
(function () {
  'use strict';

  window.nasos.player = window.nasos.player || {};

  var player = null;
  var currentSongMeta = null;
  var playerTimerStartTime = new Date();

  $(document).on('click touch', function () {
    playerTimerStartTime = new Date();
  });

  window.nasos.player.engine = {

    init: function () {
      var engine = this;

      player = new MediaElement('n-audio', {
        // Do not forget to put a final slash (/)
        pluginPath: 'https://cdnjs.cloudflare.com/ajax/libs/mediaelement/4.2.3/',
        // this will allow the CDN to use Flash without restrictions
        // (by default, this is set as `sameDomain`)
        shimScriptAccess: 'always',
        success: function (mediaElement) {
          $(mediaElement).on('ended', function () {
            if (new Date().getTime() - playerTimerStartTime.getTime() < 30 * 60 * 1000) {
              nasos.metrics.reachGoal('UI_PLAYER_AUTO_NEXT');
              engine.skipNext();
            } else {
              nasos.metrics.reachGoal('UI_PLAYER_AUTO_STOP');
              engine.pause();
            }
          });
          $(mediaElement).on('timeupdate', function () {
            var currentTimeSeconds = Math.round(player.getCurrentTime());
            var currentTimePercent = currentTimeSeconds / currentSongMeta.song.duration;

            engine.currentTimeUpdatedCallback(currentTimeSeconds, currentTimePercent);
          });
        }
      });
    },

    currentTimeUpdatedCallback: function () {
    },

    play: function (songMeta) {
      if (currentSongMeta && currentSongMeta !== songMeta) {
        this.pause();
      }

      if (!songMeta) {
        return;
      }

      nasos.metrics.reachGoal('UI_PLAYER_PLAYING');

      if (currentSongMeta === songMeta) {
        player.play();
      } else {
        if (currentSongMeta) {
          this.songUnPlayedCallback(
              currentSongMeta.row, currentSongMeta.button);
        }

        currentSongMeta = songMeta;

        player.setSrc(songMeta.song.downloadUrl);
        player.load();
        player.setVolume(0.8);
      }

      this.songPlayedCallback(currentSongMeta.row, currentSongMeta.button);
    },

    songUnPlayedCallback: function () {
    },

    songPlayedCallback: function () {
    },

    playCurrentSong: function () {
      this.play(currentSongMeta);
    },

    pause: function () {
      if (!currentSongMeta) {
        return;
      }

      player.pause();

      this.songPausedCallback(currentSongMeta.button);
    },

    songPausedCallback: function () {
    },

    getCurrentSongIndex: function () {
      for (var i = 0; i < currentSongMeta.metas.length; i++) {
        if (currentSongMeta === currentSongMeta.metas[i]) {
          return i;
        }
      }
      return null;
    },

    skipPrevious: function () {
      var previousIndex = this.getCurrentSongIndex() - 1;
      if (previousIndex < 0) {
        previousIndex = currentSongMeta.metas.length - 1;
      }
      this.play(currentSongMeta.metas[previousIndex]);
    },

    skipNext: function () {
      var nextIndex = this.getCurrentSongIndex() + 1;
      if (nextIndex >= currentSongMeta.metas.length) {
        nextIndex = 0;
      }
      this.play(currentSongMeta.metas[nextIndex]);
    },

    seek: function (currentTimePercent) {
      player.setCurrentTime(currentTimePercent * currentSongMeta.song.duration);
    }

  };
})();
