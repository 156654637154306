/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function () {

  'use strict';

  window.nasos.utils = window.nasos.utils || {
    secondsToStr: function (seconds) {
      var secondsPart = seconds % 60;
      var minutesPart = (seconds - secondsPart) / 60;
      return minutesPart + ':' +
        (secondsPart > 9 ? secondsPart : ('0' + secondsPart));
    },

    stringHashCode: function (str) {
      if (!str || str.length == 0) {
        return 0;
      }

      var hash = 0;

      for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
      }

      return hash;
    },

    localStorageGet(key, defaultValue, errorValue) {
      try {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
          return defaultValue;
        }

        const item = JSON.parse(itemStr);

        return new Date().getTime() <= item.timestampInMillis + item.ttlInMillis
          ? item.value
          : defaultValue;

      } catch (e) {
        return errorValue;
      }
    },

    localStorageSet(key, value, ttlInMillis) {
      try {
        const item = {
          value: value,
          ttlInMillis: ttlInMillis,
          timestampInMillis: new Date().getTime()
        };
        return localStorage.setItem(key, JSON.stringify(item));
      } catch (e) {
        // ignore; nothing to do here
      }
    }
  };

})();
