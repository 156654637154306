/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function () {
  'use strict';

  nasos.feedback = nasos.feedback || {};

  nasos.feedback.widget = {

    init: function () {
      var dialog = document.querySelector('dialog#feedback-dialog');
      var showDialogButton = document.querySelector('#show-feedback-dialog');
      var self = this;
      if (!dialog.showModal) {
        dialogPolyfill.registerDialog(dialog);
      }
      showDialogButton.addEventListener('click', function () {
        self.showFeedbackModal(dialog);
      });
      dialog.querySelector('.close-icon').addEventListener('click', function () {
        dialog.close();
      });
      dialog.querySelector('.close').addEventListener('click', function () {
        dialog.close();
      });
      dialog.querySelector('.send').addEventListener('click', function () {
        self.sendFeedback(dialog);
      });
    },

    showFeedbackModal: function (dialog) {
      dialog.querySelector('.feedback-form').classList.remove('n-hide');
      dialog.querySelector('.success-message').classList.add('n-hide');
      var descriptionTextarea = dialog.querySelector('#feedbackDescription');
      descriptionTextarea.value = '';
      dialog.querySelector('#feedbackName').value = '';
      dialog.querySelector('#feedbackEmail').value = '';
      descriptionTextarea.classList.remove('error');
      dialog.querySelector('.error-message').classList.add('n-hide');
      dialog.showModal();
      dialog.addEventListener('click', function (event) {
        var rect = dialog.getBoundingClientRect();
        var isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left
            <= event.clientX && event.clientX <= rect.left + rect.width);
        if (!isInDialog) {
          dialog.close();
        }
      });
    },

    sendFeedback: function (dialog) {
      var descriptionTextarea = dialog.querySelector('#feedbackDescription');
      var description = descriptionTextarea.value;
      if (0 === description.length) {
        descriptionTextarea.classList.add('error');
        dialog.querySelector('.error-message').classList.remove('n-hide');
        return;
      }

      var name = dialog.querySelector('#feedbackName').value;
      var email = dialog.querySelector('#feedbackEmail').value;
      var text = ':bell: *HitVK like* Feedback :bell:\n'
          + '*Name*: ' + name + '\n'
          + '*Email*: ' + email + '\n'
          + '*Message*: ' + description + '\n'
          + '*Version*: 241011064554\n'
          + '*User Agent*: ' + window.navigator.userAgent + '\n'
          + '*Url*: ' + window.location.href + '\n';

      $.ajax({
        url: 'https://123bazar.name/varys/post-message.php',
        method: 'POST',
        data: {channel: 'pr-muza-feedback', text: text}
      }).always(function () {
        dialog.querySelector('.feedback-form').classList.add('n-hide');
        dialog.querySelector('.success-message').classList.remove('n-hide');

        setTimeout(function () {
          dialog.close();
        }, 2000);
      })
    }
  };
})();
