/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function () {
  'use strict';

  window.nasos.player = window.nasos.player || {};

  var engine = null;

  var songMetas = [];

  window.nasos.player.widget = {

    init: function () {
      engine = window.nasos.player.engine;
      engine.init();

      var currentTimeSliderEls = $('#n-current-time');
      var currentTimeSliderEl = currentTimeSliderEls.get(0);
      var currentTimeStrEls = $('#n-current-time-str');

      window.setTimeout(function () {
        currentTimeSliderEl.MaterialSlider.onFixedMouseDown = function () {
          var currentTimePercent = currentTimeSliderEls.val() / 1000.0;
          engine.seek(currentTimePercent);
        };
      }, 0);

      $('#n-play').click(function () {
        // nasos.metrics.reachGoal('UI_PLAYER_PLAY');
        engine.playCurrentSong();
      });

      $('#n-pause').click(function () {
        // nasos.metrics.reachGoal('UI_PLAYER_PAUSE');
        engine.pause();
      });

      $('#n-skip-previous').click(function () {
        // nasos.metrics.reachGoal('UI_PLAYER_PREVIOUS');
        engine.skipPrevious();
      });

      $('#n-skip-next').click(function () {
        // nasos.metrics.reachGoal('UI_PLAYER_NEXT');
        engine.skipNext();
      });

      $('#n-player-close').click(function () {
        // nasos.metrics.reachGoal('UI_PLAYER_CLOSE');
        engine.pause();
        $('#n-player').addClass('n-hide');
        $('#n-player-backdrop').addClass('n-hide');
      });

      engine.currentTimeUpdatedCallback = function (currentTimeSeconds,
          currentTimePercent) {
        currentTimeSliderEl.MaterialSlider.change(1000.0 * currentTimePercent);
        currentTimeStrEls.text(window.nasos.utils.secondsToStr(currentTimeSeconds));
      };

      engine.songUnPlayedCallback = function (songRow, songRowButton) {
        songRow.removeClass('mdl-color--grey-100');
        songRow.find('.n-songs-title').removeClass('mdl-color-text--primary');
        songRowButton.addClass('mdl-button--accent');
        songRowButton.removeClass('mdl-button--primary');
      };

      engine.songPlayedCallback = function (songRow, songRowButton) {
        songRow.addClass('mdl-color--grey-100');
        songRow.find('.n-songs-title').addClass('mdl-color-text--primary');
        songRowButton.find('i').text('pause');
        songRowButton.addClass('mdl-button--primary');
        songRowButton.removeClass('mdl-button--accent');

        $('#n-play').addClass('n-hide');
        $('#n-pause').removeClass('n-hide');
        $('#n-player').removeClass('n-hide');
        $('#n-player-backdrop').removeClass('n-hide');
      };

      engine.songPausedCallback = function (songRowButton) {
        songRowButton.find('i').text('play_arrow');
        $('#n-play').removeClass('n-hide');
        $('#n-pause').addClass('n-hide');
        $('#n-player').removeClass('n-hide');
        $('#n-player-backdrop').removeClass('n-hide');
      };
    },

    addNextSongAndBuildButton: function (song, rowEl) {
      var playButton = $('<button class="mdl-button mdl-js-button ' +
          'mdl-button--fab mdl-button--mini-fab mdl-js-ripple-effect ' +
          'mdl-button--accent n-songs-button-play" ' +
          (song.isSongBanned ? 'disabled' : '') + '/>')
      .append($('<i class="material-icons">play_arrow</i>'));

      if (!song.isSongBanned) {
        var songMeta = {
          metas: songMetas,
          song: song,
          button: playButton,
          row: rowEl
        };
        songMetas.push(songMeta);

        playButton.click(function () {
          if (playButton.find('i').text() === 'play_arrow') {
            // nasos.metrics.reachGoal('UI_LIST_PLAY');
            nasos.player.engine.play(songMeta);
          } else {
            // nasos.metrics.reachGoal('UI_LIST_PAUSE');
            nasos.player.engine.pause();
          }
        });
      }

      return playButton;
    }

  };
})();
