/* eslint-env browser */
/* eslint-disable max-len */
(function() {
  'use strict';

  window.nasos.bann = window.nasos.bann || {};

  var artistRules = [];
  var titleRules = [];

  // RULES START

  // Валик Барвенко

  artistRules.push(/^.*валик.+барвенко.*$/im);
  titleRules.push(/^.*$/im);

  // dj rafo aydin

  artistRules.push(/^.*dj.+rafo.*$/im);
  titleRules.push(/^.*$/im);

  artistRules.push(/^.*$/im);
  titleRules.push(/^.*dj.+rafo.*$/im);

  artistRules.push(/^.*rafo.+aydin.*$/im);
  titleRules.push(/^.*$/im);

  artistRules.push(/^.*$/im);
  titleRules.push(/^.*rafo.+aydin.*$/im);

  // RULES END

  window.nasos.bann.artistRules = artistRules;
  window.nasos.bann.titleRules = titleRules;
  window.nasos.bann.queryRules = [
      'беларуская брама'
  ];
})();
