/* eslint-env browser, jquery */
/* eslint-disable max-len */
(function() {
  'use strict';

  window.nasos.rek = window.nasos.rek || {};

  window.nasos.rek.engine = {

    isTargetMobileDevice: function() {
      return device.mobile() || device.tablet();
    },

    getTopBannerContainerWidth: function() {
      return $('#n-rek-top-banner').width();
    },

    setTopBannerContainerMaxWidth: function(width) {
      $('#n-rek-top-banner').css('max-width', width + 'px');
    },

    hideTopBannerRow: function() {
      $('#n-rek-top-banner-row').hide();
    },

    setListTopBannerContainerMaxWidth: function(width) {
      $('#n-rek-list-top-banner').css('max-width', width + 'px');
    },

    hideListTopBannerRow: function() {
      $('#n-rek-list-top-banner-row').hide();
    },

    setListMiddleBannerContainerMaxWidth: function(width) {
      $('#n-rek-list-middle-banner').css('max-width', width + 'px');
    },

    hideListMiddleBannerRow: function() {
      $('#n-rek-list-middle-banner-row').hide();
    }

  };
})();
