/* eslint-env browser */
/* global MaterialSlider */
(function() {
  'use strict';

  MaterialSlider.prototype.onInputOrig_ =
    MaterialSlider.prototype.onInput_;

  MaterialSlider.prototype.onInput_ = function(event) {
    this.onInputOrig_(event);

    if (this.onFixedMouseDown) {
      this.onFixedMouseDown();
    }
  };
})();
