/* eslint-env browser, jquery */
/* eslint-disable require-jsdoc */
(function() {
  'use strict';

  $(function() {

    nasos.page.init();
    
    nasos.player.widget.init();

    nasos.search.widget.init();

    nasos.category.initWidgets();

    nasos.listWidget.init();

    nasos.search.lastQueriesWidget.init();

    nasos.feedback.widget.init();

    componentHandler.upgradeAllRegistered();
    
  });
})();
