/* eslint-env browser, jquery */
/* eslint-disable max-len */
(function () {
  'use strict';

  var listIconNames = [
    'card_giftcard', 'face', 'favorite', 'grade', 'loyalty', 'motorcycle',
    'pets', 'stars', 'thumb_up', 'touch_app', 'library_music', 'mic',
    'music_video', 'play_circle_outline', 'queue_music', 'radio',
    'phonelink_ring', 'voicemail', 'airplanemode_active',
    'settings_system_daydream', 'bubble_chart', 'highlight', 'insert_emoticon',
    'insert_photo', 'mode_edit', 'cloud_circle', 'headset', 'speaker', 'toys',
    'watch', 'assistant', 'audiotrack', 'camera_alt', 'color_lens',
    'filter_drama', 'filter_vintage', 'movie_filter', 'nature_people',
    'wb_sunny', 'local_florist', 'near_me', 'directions_walk', 'adb', 'ac_unit',
    'beach_access', 'casino', 'child_care', 'spa', 'cake', 'notifications',
    'pages', 'sentiment_satisfied', 'whatshot'
  ];

  nasos.category = nasos.category || {

    initWidgets: function () {
      this.printCategoryChips($('#n-primary-category'), 'recommendations');
      this.printCategoryChips($('#n-secondary-category-bottom'), 'popular');
      this.printCategoryChips($('#n-secondary-category-bottom'), 'national');
      this.printCategoryLists($('#n-secondary-category-right'), 'popular');
      this.printCategoryLists($('#n-secondary-category-right'), 'national');

      componentHandler.upgradeAllRegistered();
    },

    printCategoryChips: function (rootEl, categoryId) {
      var that = this;

      var category = this.categories[categoryId];

      rootEl.append(
        $('<h4 class="mdl-color-text--primary-dark"/>')
        .text(category.title)
      );

      $(category.lists).each(function (j, list) {
        rootEl.append(
          $('<span class="mdl-chip mdl-color--primary"/>').append(
            $('<a class="mdl-chip__text mdl-color-text--white"/>')
            .text(list.title)
            .attr('href', that.buildListUrl(list))
          )
        );
        rootEl.append($('<span/>').text(' '));
      });
    },

    printCategoryLists: function (rootEl, categoryId) {
      var that = this;

      var category = this.categories[categoryId];

      rootEl.append(
        $('<h4 class="mdl-color-text--primary-dark"/>')
        .text(category.title)
      );

      var listEl = $('<ul class="mdl-list n-category-list"/>');

      var listIconIndex = -1;

      $(category.lists).each(function (j, list) {
        listIconIndex++;

        listEl.append(
          $('<li class="mdl-list__item"/>').append(
            $('<span class="mdl-list__item-primary-content"/>').append(
              $('<i class="material-icons mdl-list__item-icon"/>')
              .text(listIconNames[listIconIndex % listIconNames.length])).append(
              $('<a class="mdl-color-text--grey-900"/>')
              .text(list.title)
              .attr('href', that.buildListUrl(list))
            )
          )
        );
      });

      rootEl.append(listEl);
    },
    buildListUrl: function (list) {
      return (list.type === 'playlist')
        ? nasos.env.buildPlaylistUrl(list.id)
        : nasos.env.buildSearchByTextUrl(list.query);
    },

    categories: {
      recommendations: {
        title: 'Рекомендуем послушать',
        lists: [
          {title: 'Русские хиты', type: 'playlist', id: 'audio_playlist-147845620_7'},
          {title: 'Мировые хиты', type: 'playlist', id: 'audio_playlist-147845620_8'},
          {title: 'Музыка в машину', type: 'playlist', id: 'audio_playlist-117742128_84942328'},
          {title: 'Дискотека 80-90 х', type: 'playlist', id: 'audio_playlist-20261755_22606634'},
          {title: 'Музыка 2000-х', type: 'search', query: 'Музыка 2000-х'},
          {title: 'Рингтон на звонок', type: 'playlist', id: 'audio_playlist-18939727_4745443'},
          {title: 'Классическая музыка', type: 'search', query: 'Classical Music'},
          {title: 'Русский шансон', type: 'search', query: 'русский шансон'},
          {title: 'Евровидение 2019', type: 'search', query: 'Евровидение 2019'},
          {title: 'Детские песни', type: 'search', query: 'детские песни'}
        ]
      },
      popular: {
        title: 'Популярные сборники',
        lists: [
          {title: 'Аудиосказки', type: 'search', query: 'аудиосказки'},
          {title: 'Прикольные рингтоны', type: 'search', query: 'Прикольные рингтоны'},
          {title: 'Русские народные сказки', type: 'search', query: 'Русские народные сказки'},
          {title: 'Любимые грустные песни', type: 'playlist', id: 'audio_playlist-127481200_84726349'},
          {title: 'Романтические песни', type: 'playlist', id: 'audio_playlist-127481200_85393958'},
          {title: 'Песни про любовь', type: 'search', query: 'Песни про любовь'},
          {title: 'Песни из советских фильмов', type: 'search', query: 'Песни из советских фильмов'},
          {title: 'Хиты радиостанций', type: 'playlist', id: 'audio_playlist-127481200_85393931'}
        ]
      },
      national: {
        title: 'Национальные песни',
        lists: [
          {title: 'Узбекские', type: 'search', query: 'Узбекские Песни'},
          {title: 'Азербайджанские песни', type: 'search', query: 'Азербайджанская'},
          {title: 'Даргинская', type: 'search', query: 'Даргинская'},
          {title: 'Молдавские', type: 'search', query: 'Молдавские'},
          {title: 'Казачьи песни', type: 'search', query: 'Казачьи песни'},
          {title: 'Татарская музыка', type: 'search', query: 'Татарская музыка'},
          {title: 'Украинские свадебные', type: 'search', query: 'Украинские свадебные'},
          {title: 'Чувашские', type: 'search', query: 'чувашские'},
          {title: 'Казахские', type: 'search', query: 'Казахские Песни'},
          {title: 'Индийская музыка', type: 'search', query: 'Индийская'},
          {title: 'Лезгинка', type: 'search', query: 'Лезгинка'},
          {title: 'Кавказские песни', type: 'search', query: 'Кавказские'},
          {title: 'Башкирские', type: 'search', query: 'Башкирские'},
          {title: 'Крымско-татарские песни', type: 'search', query: 'Крымско-татарские песни'},
          {title: 'Арабские', type: 'search', query: 'Арабские'},
          {title: 'Удмуртские', type: 'search', query: 'Удмуртские'},
          {title: 'Кумыкские', type: 'search', query: 'Кумыкские'},
          {title: 'Аварские песни', type: 'search', query: 'Аварские песни'},
          {title: 'Таджикские', type: 'search', query: 'Таджикские песни'},
          {title: 'Уйгурская музыка', type: 'search', query: 'Уйгурская музыка'},
          {title: 'Езидские', type: 'search', query: 'Езидские песни'},
          {title: 'Турецкие песни', type: 'search', query: 'Турецкие песни'},
          {title: 'Чеченские', type: 'search', query: 'Чеченские песни'},
          {title: 'Армянские песни', type: 'search', query: 'Армянские песни'},
          {title: 'Цыганские песни', type: 'search', query: 'Цыганские песни'},
          {title: 'Кубанские казаки', type: 'search', query: 'Кубанские казаки'},
          {title: 'Грузинские песни', type: 'search', query: 'Грузинские песни'},
          {title: 'Итальянская музыка', type: 'playlist', id: 'audio_playlist-154156662_47'},
          {title: 'Иранская музыка', type: 'search', query: 'Иранская музыка'},
          {title: 'Французские Песни', type: 'search', query: 'французские песни'},
          {title: 'Болгарские народные песни', type: 'search', query: 'Болгарские народные песни'},
          {title: 'Украинские народные песни', type: 'search', query: 'Украинские народные песни'}
        ]
      }

    }

  };
})();
